import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { useOrganisation } from "./organisation";

// Used to share common information about the Organisation that is currently
// accessing the dashboard.  This is part of a strategic approach to handle
// different organisation types and minimise the usage of loosely typed checks
// on the type of an organsation such as `isPaymentProvider`.  The enum and
// context should be extended to include common information about the
// Organsiation.

// The types of organisations are as follows:
// - `DirectMerchant`: Represents our regular merchant who uses our services
// directly without intermediaries.
// - `PaymentProvider`: Represents an entity that acts as an intermediary
// handling payments on behalf of merchants. This type of organisation often
// requires different handling in the UI.
// - `Unknown`: A fallback type used when the organisation type is not
// determined.
export enum OrganisationType {
  DirectMerchant = "DIRECT_MERCHANT",
  PaymentProvider = "PAYMENT_PROVIDER",
  Unknown = "UNKNOWN",
}

const isPaymentProvider = (organisation: OrganisationResource) =>
  !!organisation.links?.payment_provider;

const organisationType = (organisation?: OrganisationResource) => {
  if (!organisation) {
    return OrganisationType.Unknown;
  }

  return isPaymentProvider(organisation)
    ? OrganisationType.PaymentProvider
    : OrganisationType.DirectMerchant;
};

export const useOrganisationWithType = (): {
  organisation: OrganisationResource | undefined;
  organisationType: OrganisationType;
  isPaymentProvider: boolean;
} => {
  const organisation = useOrganisation();
  return {
    organisation: organisation,
    organisationType: organisationType(organisation),
    isPaymentProvider: organisation ? isPaymentProvider(organisation) : false,
  };
};
