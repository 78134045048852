import { isRole, Role } from "src/common/config";
import _ from "lodash";

import { CountryCodeSupported } from "./country";

export const isSandboxEnvironment = isRole(Role.Sandbox);

export const DoubleReferralRewardEndDate = "26 June 2024";

export const isCountryCodeSupported = (countryCode: string) =>
  _.toUpper(countryCode) in CountryCodeSupported;

export const DOUBLE_REWARDS_REFERRAL_SCHEME_TERMS =
  "https://gocardless.com/faq/referral-double-rewards/";

// Image source: https://app.contentful.com/spaces/40w0m41bmydz/assets/66px9gYVVnabONs7ySHd19?focusedField=file&focusedLocale=de
export const referPageImageURLs: Record<string, string> = {
  [CountryCodeSupported.US]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/09bb82d9f28c3d98ed91d241be77a500/Refer-page.jpg",
  [CountryCodeSupported.CA]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/7be9e7719a32fccbcc6205aedce4f656/Refer-page.jpg",
  [CountryCodeSupported.DK]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/fd3fbb5befc64a8a148408d79019632d/DK-refer-page.png",
  [CountryCodeSupported.AU]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/2529d4c5f75a1c1137e867e34143c4ef/Refer-page.jpg",
  [CountryCodeSupported.SE]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/440406c9184bddd097e98f68920b90a5/Refer-page__1_.jpg",
  [CountryCodeSupported.ES]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/2acb229e456e2003520c865b02d7f59a/Refer-page.jpg",
  [CountryCodeSupported.FR]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/3aa92d1ddec83157b186c8d6a35bc5f9/Refer-page.jpg",
  [CountryCodeSupported.GB]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/e13fadbb1b86fd6ceb30b378624dd0ce/Refer-page.jpg",
  [CountryCodeSupported.NZ]:
    "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/7be9e7719a32fccbcc6205aedce4f656/Refer-page.jpg",
};

// Image sorce: https://app.contentful.com/spaces/40w0m41bmydz/assets/14mduUfXAutlen5C3Te8kM?focusedField=file&focusedLocale=sv
// and https://app.contentful.com/spaces/40w0m41bmydz/assets/5SxSoqVnhLSzmQBW3wM3XX?focusedField=file&focusedLocale=da-DK
export const marketingImageURLs: Record<string, string> = {
  [CountryCodeSupported.AU]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/4bcfb5724e671e6e35bad51d5cdb59b4/Login-Signup.png",
  [CountryCodeSupported.CA]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/ec9832046fa48a09c1fa2609d149fdc6/Login-Signup.png",
  [CountryCodeSupported.DK]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/bbc8f917df6668f7ee6ca72e0cb979b2/DK-Signin-image.png",
  [CountryCodeSupported.ES]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/46791fc841bb9be45f1d7fb2c23b9cf6/Login-Signup.png",
  [CountryCodeSupported.FR]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/28acf2fd4ee101a1d35780ad7914b2ac/Login-Signup.png",
  [CountryCodeSupported.GB]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/2793201a76a63a3c535790661f4be2f1/Login-Signup.png",
  [CountryCodeSupported.NZ]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/ec9832046fa48a09c1fa2609d149fdc6/Login-Signup.png",
  [CountryCodeSupported.SE]:
    "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/b2949b91775ce17b3a411157f52fa083/Login-Signup__1_.png",
  [CountryCodeSupported.US]:
    "https://images.ctfassets.net/40w0m41bmydz/5SxSoqVnhLSzmQBW3wM3XX/2290e8d0c8ce5f786b01e36c403a7424/Login-Signup.png",
};

export const defaultDoubleRewardsReferPageImageURL =
  "https://images.ctfassets.net/40w0m41bmydz/66px9gYVVnabONs7ySHd19/d4c1af6ea1c13c9041b7257d436ddf65/Refer-page.jpg";
export const defaultDoubleRewardsMarketingImageURL =
  "https://images.ctfassets.net/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/89f7a6eb380cab7f783870273d97abe1/Login-Signup.png";
