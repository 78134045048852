import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import {
  PaymentAutoretryConfigurationListQueryParams,
  PaymentAutoretryConfigurationResource,
  PaymentAutoretryConfigurationUpdateRequestBody,
  PaymentAutoretryConfigurationUpdateResponseBody,
} from "@gocardless/api/dashboard/types";
import api from "@gocardless/api/utils/api";
import {
  UseMutationParams,
  useMutation,
} from "@gocardless/api/utils/use-mutation";
import { usePaymentAutoretryConfigurationList } from "@gocardless/api/dashboard/payment-autoretry-configuration";

import { useRelevantCreditor } from "./creditor";

export const paymentAutoretryConfigurationUpdate = async (
  body: PaymentAutoretryConfigurationUpdateRequestBody[],
  queryParams?: PaymentAutoretryConfigurationListQueryParams
): Promise<PaymentAutoretryConfigurationUpdateResponseBody> => {
  if (!queryParams?.creditor) {
    throw new Error("creditor is missing from query parameters");
  }

  return api.API.put(
    getEndpointURL(Endpoint.PaymentAutoretryConfigurationUpdate),
    {
      json: {
        payment_autoretry_configurations: body,
      },
      searchParams: { creditor: queryParams.creditor },
    }
  ).json();
};

export const usePaymentAutoretryConfigurationUpdate = (
  params?: UseMutationParams<
    PaymentAutoretryConfigurationUpdateResponseBody,
    PaymentAutoretryConfigurationUpdateRequestBody[]
  > & { queryParams: PaymentAutoretryConfigurationListQueryParams }
) =>
  useMutation<
    PaymentAutoretryConfigurationUpdateResponseBody,
    PaymentAutoretryConfigurationUpdateRequestBody[],
    PaymentAutoretryConfigurationListQueryParams
  >(paymentAutoretryConfigurationUpdate, params);

export const usePaymentAutoretryConfigurations = () => {
  const creditorId = useRelevantCreditor()?.id;

  const { data } = usePaymentAutoretryConfigurationList(
    {
      creditor: creditorId ?? "",
    },
    !!creditorId
  );

  return data?.payment_autoretry_configurations;
};

export const useUpdatePaymentAutoretryConfigurations = ({
  onUpdateSuccess,
  onUpdateError,
}: {
  onUpdateSuccess: () => void;
  onUpdateError: () => void;
}) => {
  const creditorId = useRelevantCreditor()?.id;

  const { mutate } = usePaymentAutoretryConfigurationList(
    {
      creditor: creditorId ?? "",
    },
    !!creditorId
  );

  const [sendUpdate] = usePaymentAutoretryConfigurationUpdate({
    onSuccess: (updateResponse) => {
      mutate(updateResponse);
      onUpdateSuccess();
    },
    onError: onUpdateError,
    queryParams: {
      creditor: creditorId ?? "",
    },
  });

  return sendUpdate as (c: PaymentAutoretryConfigurationResource[]) => void;
};
