import { Currency, currencyToSuccessPlusScheme } from "src/common/currencies";
import { useOrganisation } from "src/queries/organisation";

export const useHomeScheme = () => {
  const organisation = useOrganisation();
  const domesticCurrency = organisation?.domestic_currency as Currency; // Update the type in payment-service schema to be Currency.

  if (organisation === undefined) return undefined;
  return organisation.domestic_currency
    ? currencyToSuccessPlusScheme[domesticCurrency]
    : null;
};
