import { useEffect } from "react";
import Router from "next/router";

import {
  externalNavigation,
  getRouteURL,
  isExternal,
  Route,
  RouteURLParams,
} from "../../common/routing";

interface ExternalRedirectProps {
  /**
   * Is a DOMString containing the URL of the page to navigate to
   */
  href: string;
  /**
   * Set this to true to have the new URL replace the
   * current one on the browser history stack
   */
  replace?: boolean;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({
  href,
  replace,
}) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    externalNavigation(href, replace);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

interface RouteRedirectProps
  extends RouteURLParams,
    Pick<ExternalRedirectProps, "replace"> {}

const ExternalRouteRedirect: React.FC<RouteRedirectProps> = ({
  route,
  routeParams,
  queryParams,
  replace,
}) => (
  <ExternalRedirect
    href={getRouteURL({
      route,
      routeParams,
      queryParams,
      absolute: true,
    })}
    replace={replace}
  />
);

const RouteRedirect: React.FC<RouteRedirectProps> = ({
  route,
  routeParams,
  queryParams,
  replace,
}) => {
  const url = getRouteURL({ route, routeParams, queryParams });

  useEffect(() => {
    if (replace) {
      Router.replace(url);
    } else {
      Router.push(url);
    }

    return () => {
      window.scrollTo(0, 0);
      document.body.focus();
    };
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export type RedirectProps = ExternalRedirectProps | RouteRedirectProps;

const Redirect: React.FC<RedirectProps> = (props) => {
  if ("href" in props) {
    return <ExternalRedirect {...props} />;
  }

  if (!("route" in props)) {
    throw new Error("Missing route prop in Redirect component");
  }

  if (!(props.route in Route)) {
    throw new Error("Invalid route pass to Redirect component");
  }

  if (isExternal(props.route)) {
    return <ExternalRouteRedirect {...props} />;
  }
  return <RouteRedirect {...props} />;
};

export default Redirect;
