import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { UserResource } from "@gocardless/api/dashboard/types";

const isGoCardlessAdmin = (users?: UserResource & { is_admin?: boolean }) =>
  !!users?.is_admin;

export function useIsGoCardlessAdmin(): boolean {
  const { data } = useUserShowSelf();

  if (data === undefined) {
    return false;
  }

  if (!isGoCardlessAdmin(data.users)) {
    return false;
  }

  return true;
}
