import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef } from "react";

import { getRouteURL, RouteURLParams, isExternal } from "../../common/routing";

export interface RenderPropResult {
  href: string;
  onClick?: NextLinkProps["onClick"];
  ref?: React.Ref<HTMLAnchorElement>;
}

type RenderProp = (result: RenderPropResult) => React.ReactNode;
type BaseLinkProps = Omit<NextLinkProps, "href">;

export interface LinkBuilderProps extends BaseLinkProps, RouteURLParams {
  children: RenderProp;
  anchorId?: string;
}

/**
 * This component takes the props injected into it by "next/link" and pass them
 * into a render prop. This is a workaround to "next/link" not providing a
 * render prop API.
 */
const IntoRenderProp = forwardRef<
  HTMLAnchorElement,
  RenderPropResult & {
    children: RenderProp;
  }
>((props, ref) => {
  const { children, href, onClick } = props;

  return <>{children({ href, onClick, ref })}</>;
});

const pluckNextLinkProps = (
  props: LinkBuilderProps
): Pick<LinkBuilderProps, keyof BaseLinkProps> => {
  const { as, replace, scroll, shallow, passHref, prefetch } = props;
  return { as, replace, scroll, shallow, passHref, prefetch };
};

/**
 * LinkBuilder converts the "next/link" component into a render prop API.
 * You can use this component to inject single-page navigation capabilities into
 * a custom link. Use Link for all other cases.
 * If side effects to the click are required (sending events, stoping redirection, ...),
 * you can achieve this by passing an onClick props to this component;
 * please avoid using `onClick` to the returned children component
 */
const LinkBuilder: React.FC<LinkBuilderProps> = (props) => {
  const { route, routeParams, queryParams, children, onClick, anchorId } =
    props;
  const isExternalRoute = isExternal(route);
  const href = getRouteURL({
    route,
    routeParams,
    queryParams,
    anchorId,
    absolute: isExternalRoute,
  });

  if (isExternalRoute) {
    return <>{children({ href, onClick })}</>;
  }

  return (
    <NextLink href={href} {...pluckNextLinkProps(props)} legacyBehavior={true}>
      <IntoRenderProp onClick={onClick} href={href}>
        {children}
      </IntoRenderProp>
    </NextLink>
  );
};

export default LinkBuilder;
