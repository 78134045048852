import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { MULTI_ENTITY_CONFIG_KEY } from "../constants";

interface MultiEntitySessionStorage {
  currentOrgId: OrganisationResource["id"];
  previousOrgId: OrganisationResource["id"];
  isBannerDismissed: boolean;
}
export const useMultiEntitySessionStorage = () => {
  const storage: MultiEntitySessionStorage = JSON.parse(
    sessionStorage.getItem(MULTI_ENTITY_CONFIG_KEY) ?? "{}"
  );

  const setSessionStorage = (values: Partial<MultiEntitySessionStorage>) => {
    sessionStorage.setItem(
      MULTI_ENTITY_CONFIG_KEY,
      JSON.stringify({
        ...storage,
        ...values,
      })
    );
  };

  return {
    sessionStorage: storage,
    setSessionStorage,
  };
};
