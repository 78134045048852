import { useRouter } from "next/router";

interface UseFullStory {
  enableFullStory: boolean;
}

/**
 * for the logic to decide whether we should enable fullstory
 * returns a boolean `enableFullStory`
 */
export const useFullStory = (routes: (string | RegExp)[]): UseFullStory => {
  const { asPath } = useRouter();

  const isAllowedRoute =
    routes.find((route) => {
      if (route instanceof RegExp) {
        return route.test(asPath);
      } else {
        return route === asPath;
      }
    }) !== undefined;

  return {
    enableFullStory: isAllowedRoute,
  };
};
