import {
  SchemeScheme as SuccessPlusScheme,
  PaymentAutoretryConfigurationResource,
} from "@gocardless/api/dashboard/types";
import { orderBy } from "lodash";
import { UserLocale } from "src/technical-integrations/i18n";

export const PRICING_TERMS_EN =
  "https://gocardless.com/assets/40w0m41bmydz/1abVLJTHAlEaewdaPCaMPF/c39e3ac3aa73643c3680a5310c2ddbe7/Success_Plus_Pricing_Terms_EN.pdf";
const PRICING_TERMS_FR =
  "https://gocardless.com/assets/40w0m41bmydz/1abVLJTHAlEaewdaPCaMPF/8621bda2086db81a2db9077f52935d52/Success_Plus_Pricing_Terms_FR.pdf";
const PRICING_TERMS_ES =
  "https://gocardless.com/assets/40w0m41bmydz/1abVLJTHAlEaewdaPCaMPF/2a587277f22eb0cdeba6e31708835eb7/Success_Plus_Pricing_Terms_ES.pdf";
const PRICING_TERMS_DE =
  "https://gocardless.com/assets/40w0m41bmydz/1abVLJTHAlEaewdaPCaMPF/bce31e22536968732a96f959aff4845b/Success_Plus_Pricing_Terms_DE.pdf";

export const DEFAULT_WINDOW_OFFSET = 0;

export const MAX_PERMITTED_WINDOW_OFFSET = 12;

export const MIN_PERMITTED_WINDOW_OFFSET = 1;

export const MAX_PERMITTED_RETRY_WINDOW = 42;

export const DEFAULT_RETRY_WINDOW = 14;

export const MIN_PERMITTED_RETRY_WINDOW = 7;

export const CUSTOM_CONFIGURATION_OPTION = "Custom...";

export const translatedTerms: Record<UserLocale, string> = {
  [UserLocale.EN_GB]: PRICING_TERMS_EN,
  [UserLocale.EN_US]: PRICING_TERMS_EN,
  [UserLocale.DE_DE]: PRICING_TERMS_DE,
  [UserLocale.FR_FR]: PRICING_TERMS_FR,
  [UserLocale.ES_ES]: PRICING_TERMS_ES,
};

export enum ReportingDateRange {
  OneMonth = "one_month",
  ThreeMonths = "three_months",
  AllTime = "all_time",
  ChooseDates = "choose_dates",
}

export enum MaxPaymentConfirmationDays {
  TwoWeeks = "14",
  FourWeeks = "28",
  SixWeeks = "42",
}

export const successPlusSchemes: Exclude<
  SuccessPlusScheme,
  SuccessPlusScheme.Betalingsservice
>[] = [
  SuccessPlusScheme.Bacs,
  SuccessPlusScheme.SepaCore,
  SuccessPlusScheme.Becs,
  SuccessPlusScheme.BecsNz,
  SuccessPlusScheme.Ach,
  SuccessPlusScheme.Pad,
  SuccessPlusScheme.Autogiro,
];

type SchemeOrder = Record<
  SuccessPlusScheme,
  Exclude<SuccessPlusScheme, SuccessPlusScheme.Betalingsservice>[]
>;

export const schemeOrderFromHomeScheme: SchemeOrder = {
  [SuccessPlusScheme.Ach]: [
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Autogiro,
  ],
  [SuccessPlusScheme.Autogiro]: [
    SuccessPlusScheme.Autogiro,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Becs,
  ],
  [SuccessPlusScheme.Bacs]: [
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Autogiro,
  ],
  [SuccessPlusScheme.Becs]: [
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Autogiro,
  ],
  [SuccessPlusScheme.BecsNz]: [
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Autogiro,
  ],
  [SuccessPlusScheme.Betalingsservice]: [
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.Autogiro,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
  ],
  [SuccessPlusScheme.Pad]: [
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Autogiro,
  ],
  [SuccessPlusScheme.SepaCore]: [
    SuccessPlusScheme.SepaCore,
    SuccessPlusScheme.Bacs,
    SuccessPlusScheme.Ach,
    SuccessPlusScheme.Pad,
    SuccessPlusScheme.Becs,
    SuccessPlusScheme.BecsNz,
    SuccessPlusScheme.Autogiro,
  ],
};

export const orderSchemeConfigs = (
  homeScheme: SuccessPlusScheme | null,
  rawSchemeConfigs: PaymentAutoretryConfigurationResource[]
) => {
  const orderedSchemeConfigs: PaymentAutoretryConfigurationResource[] = [];

  if (homeScheme === null) {
    return orderBy(rawSchemeConfigs, "desc");
  }

  const schemeOrder = schemeOrderFromHomeScheme[homeScheme];

  for (const scheme of schemeOrder) {
    const rawSchemeConfig = rawSchemeConfigs.find((c) => c.scheme === scheme);
    if (rawSchemeConfig) {
      orderedSchemeConfigs.push(rawSchemeConfig);
    }
  }

  return orderedSchemeConfigs;
};
