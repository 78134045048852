/**
 * Copied from flux while this component is in this codebase rather than the
 * flux codebase.
 */

import { useEffect } from "react";

export const useResizeObserver = (
  /** The element to observe. */
  elementRef: React.RefObject<HTMLElement>,
  /** A callback to call on resize. */
  callback: () => void,
  /**
   * An optional list of dependencies to track in the underlying use effect.
   *
   * For example, if a tooltip element is conditionally rendered then elementRef
   * will contain a null reference until some `open` prop is true. Adding the
   * `open` prop to the deps list will setup the resize observer when the
   * tooltip is rendered.
   */
  deps: unknown[] = []
) => {
  useEffect(() => {
    if (typeof window === "undefined" || window.document == null) {
      return;
    }
    const el = elementRef.current;
    if (!el) {
      return;
    }
    // If ResizeObserver is not available for a browser then use a traditional
    // window resize event listener
    const handleResize = () => {
      callback();
    };
    if (typeof window.ResizeObserver === "undefined") {
      window.addEventListener("resize", handleResize, false);
      return () => window.removeEventListener("resize", handleResize, false);
    } else {
      const ro = new ResizeObserver((entries) => {
        if (!entries.length) {
          return;
        }
        handleResize();
      });
      ro.observe(el);
      return () => {
        if (!el) {
          return;
        }
        ro.unobserve(el);
      };
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, callback, ...deps]);
};

export const getDocumentBody = (): HTMLElement | null => {
  if (typeof document === "undefined" || typeof document.body === "undefined") {
    return null;
  }
  return document.body;
};
