import { FC } from "react";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import {
  FullStoryAllowedRoutes,
  KccFullStoryAllowedRoutes,
  usVerificationImprovementAllowedRoutes,
} from "src/common/routing/routes";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

import { FullStoryRecorder } from "./FullStoryRecorder";

interface FullStoryProps {
  withOptimizely?: boolean;
}

export const FullStory: FC<FullStoryProps> = ({ withOptimizely = false }) => {
  if (withOptimizely) {
    return <FullStoryWithOptimizely />;
  }

  return <FullStoryRecorder routes={FullStoryAllowedRoutes} />;
};

const FullStoryWithOptimizely: FC<FullStoryProps> = () => {
  const { isVariationOn: kccEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY,
  });

  const { isFlagEnabled: usVerificationImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.US_VERIFICATION_IMPROVEMENTS,
    });

  const routes = [...FullStoryAllowedRoutes];

  if (kccEnabled) {
    routes.push(...KccFullStoryAllowedRoutes);
  }

  if (usVerificationImprovementsEnabled) {
    routes.push(...usVerificationImprovementAllowedRoutes);
  }

  return <FullStoryRecorder routes={routes} />;
};
