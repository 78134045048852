import { useDefaultCreditor } from "src/queries/creditor";
import { useHasIntelligentRetriesEnabled } from "src/components/routes/SuccessPlus/api/index.hooks";

import { Redirect, Route } from "../../components/routing";

const ConfigurationPresetRestriction: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const defaultCreditor = useDefaultCreditor();
  const hasRetriesEnabled = useHasIntelligentRetriesEnabled();

  if (defaultCreditor === undefined) {
    return null;
  }

  if (hasRetriesEnabled) {
    return <Redirect route={Route.SuccessPlusReporting} />;
  } else {
    return <>{children}</>;
  }
};

export default ConfigurationPresetRestriction;
