import {
  Link as StyledLink,
  LinkProps as StyleLinkProps,
} from "@gocardless/flux-react";

import LinkBuilder, { LinkBuilderProps } from "./LinkBuilder";
type SharedProps = Omit<StyleLinkProps, "href">;

type LinkProps = SharedProps &
  ({ href: string; anchorId?: never } | Omit<LinkBuilderProps, "children">);

const Link = (props: LinkProps) =>
  "href" in props ? (
    <StyledLink {...props} />
  ) : (
    <LinkBuilder {...props} passHref>
      {(result) => <StyledLink {...props} {...result} />}
    </LinkBuilder>
  );

export default Link;
