import { FC, useEffect } from "react";
import { FullStory, init, isInitialized } from "@fullstory/browser";

import { useFullStory } from "./useFullStory";

const isLocal = process.env.NODE_ENV === "development";

interface FullStoryRecorderProps {
  routes: (string | RegExp)[];
}

export const FullStoryRecorder: FC<FullStoryRecorderProps> = ({ routes }) => {
  const { enableFullStory } = useFullStory(routes);

  useEffect(() => {
    const isFSReady = isInitialized();

    // FS should not be enabled - shutdown if already initialised
    if (!enableFullStory) {
      if (isFSReady) {
        FullStory("shutdown");
      }
      return;
    }

    // FS should be enabled - initialise if not already initialised,
    // or restart if already initialised
    if (!isFSReady) {
      init(
        { orgId: "1JMJG", namespace: "FS_Fullstory" },
        ({ sessionUrl }) =>
          isLocal &&
          // eslint-disable-next-line no-console
          console.log(`Started session: ${sessionUrl}`)
      );
    } else {
      FullStory("restart");
    }

    return () => {
      if (!isFSReady) return;
      FullStory("shutdown");
    };
  }, [enableFullStory]);

  return null;
};
