import { useOrganisation } from "src/queries/organisation";
import { useSchemeIdentifierList } from "@gocardless/api/dashboard/scheme-identifier";

export const useSchemeIdentifiers = () => {
  const organisation = useOrganisation();
  const { data } = useSchemeIdentifierList({ organisation: organisation?.id });
  if (data === undefined || organisation === undefined) {
    return undefined;
  }
  return data.scheme_identifiers;
};
