import { useIsGoCardlessAdmin } from "./useIsGoCardlessAdmin";

const GoCardlessStaffRestriction: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isGoCardlessAdmin = useIsGoCardlessAdmin();

  if (!isGoCardlessAdmin) {
    return null;
  }

  return <>{children}</>;
};

export default GoCardlessStaffRestriction;
