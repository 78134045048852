import { useState } from "react";
import Router, { useRouter } from "next/router";
import { getErrorsFromErrorResponse, useClearSWR } from "@gocardless/api/utils";
import { OrganisationResource } from "@gocardless/api/dashboard/types";
import { useTemporaryAccessTokenCreateForOrganisation } from "@gocardless/api/dashboard/temporary-access-token";
import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  PlainButton,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Route, getRouteURL, routerPush } from "src/common/routing";
import { useOrgSwitcherData } from "src/components/routes/Home/components/OrganisationSwitcher/hooks/useOrgSwitcherData";
import { ToTranslate } from "src/components/i18n";
import { useAPIErrorNotifications } from "src/hooks/useAPIErrorNotifications";
import { useTwoFA } from "src/components/TwoFAContextProvider";
import { useMultiEntitySessionStorage } from "src/components/routes/MultiEntity/hooks/useMultiEntitySessionStorage";

import { useAccessToken } from "../authorisation";

interface SwitchOrganisationOptions {
  organisationId?: OrganisationResource["id"];
  onSuccess?: () => void;
  onError?: () => void;
}

export const useSwitchOrganisation = (options?: SwitchOrganisationOptions) => {
  const clearCache = useClearSWR();
  const { organisationId, onSuccess, onError } = options ?? {};

  const { loggedInOrganisation, parentOrganisation } = useOrgSwitcherData();

  const [accessToken, setAccessToken] = useAccessToken();
  const { showTwoFADialog } = useTwoFA();

  const [isReauthenticationRequired, setIsReauthenticationRequired] =
    useState(false);

  const router = useRouter();

  const { setSessionStorage } = useMultiEntitySessionStorage();

  const viewingChildOrganisation =
    !!parentOrganisation?.id &&
    !!loggedInOrganisation?.id &&
    parentOrganisation.id !== loggedInOrganisation.id;

  const [createAccessToken, { isMutating, error }] =
    useTemporaryAccessTokenCreateForOrganisation({
      onSuccess: (response) => {
        onSuccess?.();

        setAccessToken(response);

        setSessionStorage({
          previousOrgId: loggedInOrganisation?.id ?? "",
        });

        clearCache();

        router.push(getRouteURL({ route: Route.Home })).then(() => {
          Router.reload();
        });
      },
      onError: async (accessTokenError) => {
        onError?.();
        const errors = await getErrorsFromErrorResponse(accessTokenError);

        if (errors[0]?.reason === "two_factor_auth_missing_otp_code") {
          setIsReauthenticationRequired(true);
          showTwoFADialog({
            variant: "multiEntity",
            onSubmit: async (otpCode) =>
              switchOrganisation(organisationId, otpCode),
          });
        }

        triggerErrorNotifications();
      },
    });

  const { showErrors: triggerErrorNotifications } = useAPIErrorNotifications({
    error,
    title: <ToTranslate>Unable to switch account</ToTranslate>,
    message: (
      <Box>
        <Text preset={TypePreset.Body_02}>
          <ToTranslate>
            There was an error loading the chosen account. Please try again.
          </ToTranslate>
        </Text>
        <Box>
          <PlainButton
            onClick={() => routerPush({ route: Route.Accounts })}
            effect={HoverEffect.TextDecoration}
            spaceAbove={1.5}
          >
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
                <ToTranslate>Try again</ToTranslate>
              </Text>
              <Space layout="inline" h={0.5} />
              <Icon name={Glyph.ArrowForward} size="12px" />
            </Box>
          </PlainButton>
        </Box>
      </Box>
    ),
  });

  const switchOrganisation = async (
    orgId: OrganisationResource["id"] = "",
    otpCode?: string
  ) => {
    if (accessToken?.links?.organisation)
      await createAccessToken({
        links: { organisation: orgId },
        ...(otpCode ? { otp_code: otpCode } : {}),
      });
  };

  return {
    error,
    isReauthenticationRequired,
    submit: switchOrganisation,
    isSubmitting: isMutating,
    viewingChildOrganisation,
  };
};
