import { useEffect, useState } from "react";
import { HTTPError } from "ky-universal";
import { UpgradeType } from "@gocardless/api/staff/types";
import { useUpgradeList } from "@gocardless/api/staff/upgrade";
import {
  UpgradesStatus,
  UpgradeResource,
} from "@gocardless/api/dashboard/types";

interface EnabledUpgrade {
  loading: Boolean;
  upgrade?: UpgradeResource;
  error?: HTTPError;
}

export const useUpgradeCheck = (
  upgradeType: UpgradeType,
  upgradeStatus: UpgradesStatus
): EnabledUpgrade => {
  const [loading, setLoading] = useState(true);
  const { data, error } = useUpgradeList();

  useEffect(() => {
    if (data || error) {
      setLoading(false);
    }
  }, [data, error]);

  const upgrade = data?.upgrades?.find(
    (u: UpgradeResource) =>
      u.upgrade_type === upgradeType && u.status === upgradeStatus
  );

  return {
    upgrade,
    loading,
    error,
  };
};
